export default [
  {
    /**
     * 登录
     */
    name: 'doLogin',
    method: 'post',
    url: '/api/user/login',
  },
  {
    /**
     * 查询当前登录用户
     */
    name: 'queryAuth',
    method: 'post',
    url: '/api/user/queryAuthInfo',
  },
  {
    /**
     * 退出
     */
    name: 'loginOut',
    method: 'post',
    url: '/api/user/logOff',
  },
  {
    /**
     * 保存用户
     */
    name: 'saveUser',
    method: 'post',
    url: '/api/user/save',
  },
  {
    /**
     * 保存更新用户
     */
    name: 'updateUser',
    method: 'post',
    url: '/api/user/update',
  },
  {
    /**
     * 查询用户列表
     */
    name: 'queryUserList',
    method: 'post',
    url: '/api/user/queryList',
  },
  {
    /**
     * 查询用户组列表
     */
    name: 'queryGroupList',
    method: 'post',
    url: '/api/user/queryGroupList',
  },
  { 
    /**
     * 查询用户已选择菜单项
     */
    name: 'queryMenuIdsByUserId',
    method: 'post',
    url: '/api/user/queryMenuIdsByUserId',
  },
  {
    /**
     * 停用启用用户
     */
    name: 'switchUserEnabled',
    method: 'post',
    url: '/api/user/SwitchUserEnabled',
  },
  {
    /**
     * 发送重置密码
     */
    name: 'resetPassword',
    method: 'post',
    url: '/api/user/resetPassword',
  },
  {
    /**
     * 找回密码
     */
    name: 'findPassword',
    method: 'post',
    url: '/api/user/findPassword',
  },
  {
    /**
     * 重置密码
     */
    name: 'confirmResetPassword',
    method: 'post',
    url: '/api/user/confirmResetPassword',
  },
  {
    /**
     * 删除用户
     */
    name: 'deleteUser',
    method: 'post',
    url: '/api/user/delete',
  },
  {
    /**
     * 修改密码
     */
    name: 'changePassword',
    method: 'post',
    url: '/api/user/change',
  },
  {
    /**
     * 根据ownerId获取所有用户
     */
    name: 'queryAllUserByOwnerId',
    method: 'post',
    url: '/api/user/queryAllUserByOwnerId',
  },
  {
    /**
     * 保存用户组
     */
    name: 'saveUserGroup',
    method: 'post',
    url: '/api/user/saveGroup',
  },
  {
    /**
     * 保存更新用户组
     */
    name: 'updateUserGroup',
    method: 'post',
    url: '/api/user/updateGroup',
  },
  {
    /**
     * 保存更新用户组
     */
    name: 'deleteUserGroup',
    method: 'post',
    url: '/api/user/deleteGroup',
  },
  {
    /**
     * 获取用户组用户和站点
     */
    name: 'getGroupSiteAndUser',
    method: 'post',
    url: '/api/user/getGroupSiteAndUser',
  },
]
