export default [
  {
    /**
     * 创建自定义图表
     */
    name: 'saveNewChart',
    method: 'post',
    url: '/api/chart/save',
  },
  {
    /**
     * 更新图表基本信息
     */
    name: 'updateChart',
    method: 'post',
    url: '/api/chart/update',
  },
  {
    /**
     * 拷贝图表基本信息
     */
    name: 'copyChart',
    method: 'post',
    url: '/api/chart/copy',
  },
  {
    /**
     * 查询图表列表
     */
    name: 'queryChartList',
    method: 'post',
    url: '/api/chart/queryList',
  },
  {
    /**
     * 查询数据项
     */
    name: 'querySeriesByChartId',
    method: 'post',
    url: '/api/chart/querySeriesByChartId',
  },
  {
    /**
     * 查询数据
     */
    name: 'queryChartData',
    method: 'post',
    url: '/api/chart/queryChartData',
  },
  {
    /**
     * 查询单个图表
     */
    name: 'getChart',
    method: 'post',
    url: '/api/chart/getChart',
  },
  {
    /**
     * 保存数据集
     */
    name: 'saveSeries',
    method: 'post',
    url: '/api/chart/saveSeries',
  },
  {
    /**
     * 修改数据集
     */
    name: 'updateSeries',
    method: 'post',
    url: '/api/chart/updateSeries',
  },
  {
    /**
     * 删除单个数据集
     */
    name: 'deleteSeries',
    method: 'post',
    url: '/api/chart/deleteSeries',
  },
  {
    /**
     * 删除单个图表
     */
    name: 'deleteChart',
    method: 'post',
    url: '/api/chart/deleteChart',
  },
  {
    /**
     * 查询所有图表
     */
    name: 'queryAllChart',
    method: 'post',
    url: '/api/chart/queryAllChart',
  },
  {
    /**
     * 查询Triggers
     */
    name: 'queryTriggersByChartId',
    method: 'post',
    url: '/api/chart/queryTriggersByChartId',
  },
  {
    /**
     * 查询图表已设置参数
     */
    name: 'queryAllParameterByChartId',
    method: 'post',
    url: '/api/chart/queryAllParameterByChartId',
  },
  {
    /**
     * 保存Trigger
     */
    name: 'saveTriggers',
    method: 'post',
    url: '/api/chart/saveTriggers',
  },
  {
    /**
     * 保存TriggerupdateTriggers
     */
    name: 'updateTriggers',
    method: 'post',
    url: '/api/chart/updateTriggers',
  },
  {
    /**
     * 删除Trigger
     */
    name: 'deleteTriggers',
    method: 'post',
    url: '/api/chart/deleteTriggers',
  },
  {
    /**
     * 查询单个站点参数数据
     */
    name: 'querySiteParameterChart',
    method: 'post',
    url: '/api/chart/querySiteParameterChart',
  },
  {
    /**
     * 查询参数最新时间
     */
    name: 'queryLastDate',
    method: 'post',
    url: '/api/chart/queryLastDate',
  },
  {
    /**
     * 查询参数最新时间
     */
    name: 'getChartLastDate',
    method: 'post',
    url: '/api/chart/getChartLastDate',
  },
  {
    /**
     * 发送Email
     */
    name: 'sendChart',
    method: 'post',
    url: '/api/chart/sendChart',
  },
]
